import { ReactNode } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../utils/Form/FormHandler'
import Field from '../../utils/Form/Widgets/Field'
import AutocompleteSelect from '../../utils/Form/Widgets/AutocompleteSelect'
import Select from '../../utils/Form/Widgets/Select'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
}

class Site {
  name?: string
  code?: string
  company?: number
}

class SiteEditModal extends FormHandler {
  statusOptions = [
    {
      value: 'active',
      label: 'Active',
    },
    {
      value: 'passive',
      label: 'Disabled',
    },
  ]

  constructor(props: Props) {
    super(props)
    this.state = { entity: new Site() }
  }

  getApiUrl(): string {
    return '/company/sites/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl(): string | null {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/company/sites/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      company_sites: this.state.entity,
    }
  }
  render(): ReactNode {
    var state = this.state as any
    let title = 'Add Site'
    if (this.state.entity && this.props.itemIdForUpdate !== 'add') {
      let entity = this.state.entity as any
      title = entity.name
    }
    let defaultCompanyValue = state.entity.company || 0
    if (this.props.itemIdForUpdate === 'add') {
      defaultCompanyValue = 0
    } else {
      defaultCompanyValue = state.entity.company
    }
    let defaultCountryValue = state.entity?.company_site_addresses?.[0]?.country || 0
    if (this.props.itemIdForUpdate === 'add' || !state.entity?.company_site_addresses?.[0]) {
      // Default United Kingdom
      defaultCountryValue = 231
    } else {
      defaultCountryValue = state.entity?.company_site_addresses?.[0]?.country
    }

    return (
      <Modal
        id='kt_modal_create_company'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
            })
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Field
                title='Name'
                className='form-control'
                type='text'
                name='name'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.name}
              />
            </FormGroup>
            <FormGroup>
              <AutocompleteSelect
                title='Company'
                name='company'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={defaultCompanyValue}
                autoCompleteTokenUrl='/company/autocomplete'
              />
            </FormGroup>
            <FormGroup>
              <Select
                title='Status'
                name='status'
                options={this.statusOptions}
                defaultValue={state.entity.status}
                required={true}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Address'
                className='form-control'
                type='string'
                name='company_site_addresses.0.address'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity?.company_site_addresses?.[0]?.address}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='City'
                className='form-control'
                type='string'
                name='company_site_addresses.0.city'
                pattern='^([A-Za-z \g])+$'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity?.company_site_addresses?.[0]?.city}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='County'
                className='form-control'
                type='string'
                name='company_site_addresses.0.county'
                pattern='^([A-Za-z \g])+$'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity?.company_site_addresses?.[0]?.county}
              />
            </FormGroup>
            <FormGroup>
              <AutocompleteSelect
                title='Country'
                name='company_site_addresses.0.country'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={defaultCountryValue}
                autoCompleteTokenUrl='/company/countriesautocomplete'
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Postcode'
                className='form-control'
                type='string'
                name='company_site_addresses.0.postcode'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity?.company_site_addresses?.[0]?.postcode}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Phone'
                className='form-control'
                name='company_site_addresses.0.phone'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity?.company_site_addresses?.[0]?.phone}
                pattern='^\d+$'
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Email'
                className='form-control'
                type='string'
                name='company_site_addresses.0.email'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity?.company_site_addresses?.[0]?.email}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { SiteEditModal }
