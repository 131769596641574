import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { DataDirectoryList } from './components/DataDirectoryList'
import { SFTPConfigList } from './components/SFTPConfigList'
import DocumentTitle from 'react-document-title'
import { useIntl } from 'react-intl'
import { GeneralParametersList } from './components/GeneralParametersList'
import { EnergyTypesList } from './components/EnergyTypesList'
import { EnergySubtypesList } from './components/EnergySubtypesList'
import ConversionFactorsList from './components/ConversionFactorsList'

const settingsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Settings',
    path: '/settings/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SettingsPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='data-directory'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Data Directory List'}
              ></DocumentTitle>
              <PageTitle breadcrumbs={settingsBreadCrumbs}>
                {intl.formatMessage({ id: 'MENU.SETTINGSDATADIRECTORY' })}
              </PageTitle>
              <DataDirectoryList />
            </>
          }
        />
        <Route
          path='sftp-config'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | SFTP Config'}></DocumentTitle>
              <PageTitle breadcrumbs={settingsBreadCrumbs}>
                {intl.formatMessage({ id: 'MENU.SETTINGSSFTPCONFIG' })}
              </PageTitle>
              <SFTPConfigList />
            </>
          }
        />

        <Route
          path='general-parameters'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | General Parameters'}
              ></DocumentTitle>
              <PageTitle breadcrumbs={settingsBreadCrumbs}>
                {intl.formatMessage({ id: 'MENU.SETTINGSGENERALPARAMETERS' })}
              </PageTitle>
              <GeneralParametersList />
            </>
          }
        />
        <Route
          path='conversion-factors'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Conversion Factors'}
              ></DocumentTitle>
              <PageTitle breadcrumbs={settingsBreadCrumbs}>
                {intl.formatMessage({ id: 'MENU.SETTINGSCONVERSIONFACTORS' })}
              </PageTitle>
              <ConversionFactorsList />
            </>
          }
        />
        <Route
          path='energy-types'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Energy Types'}></DocumentTitle>
              <PageTitle breadcrumbs={settingsBreadCrumbs}>
                {intl.formatMessage({ id: 'MENU.SETTINGSENERGYTYPES' })}
              </PageTitle>
              <EnergyTypesList />
            </>
          }
        />
        <Route
          path='energy-subtypes'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Energy Subtypes'}
              ></DocumentTitle>
              <PageTitle breadcrumbs={settingsBreadCrumbs}>
                {intl.formatMessage({ id: 'MENU.SETTINGSENERGYSUBTYPES' })}
              </PageTitle>
              <EnergySubtypesList />
            </>
          }
        />
        <Route index element={<Navigate to='/settings/data-directory' />} />
      </Route>
    </Routes>
  )
}

export default SettingsPage
