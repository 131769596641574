import { useEffect } from 'react'
import { ILayout, useLayout } from '../../core'
import { useIntl } from 'react-intl'

const Footer = () => {
  const { config } = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  const intl = useIntl()
  return (
    <>
      <div className='text-dark order-2 order-md-1'>
        <span className='text-muted fw-semibold me-1'>
          {new Date().getFullYear().toString()}&copy;
        </span>
        <a
          href='https://ai-websolutions.com/'
          target='_blank'
          className='text-gray-800 text-hover-primary'
          rel='noreferrer'
        >
          AiWeb Solutions
        </a>
      </div>

      <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
        <li className='menu-item'>
          <a
            href='https://www.ai-websolutions.com/page/about'
            target='_blank'
            className='menu-link px-2'
            rel='noreferrer'
          >
            {intl.formatMessage({ id: 'FOOTER.ABOUT' })}
          </a>
        </li>
      </ul>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export { Footer }
