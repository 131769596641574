import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { CompanyList } from './components/CompanyList'
import { MetersList } from './components/MetersList'
import { SitesList } from './components/SitesList'
import DocumentTitle from 'react-document-title'

const companiesBreadCrumbs: Array<PageLink> = [
  {
    title: 'Company',
    path: '/company/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CompanyPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Companies'}></DocumentTitle>
              <PageTitle breadcrumbs={companiesBreadCrumbs}>Companies</PageTitle>
              <CompanyList />
            </>
          }
        />
        <Route
          path='sites'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Sites'}></DocumentTitle>
              <PageTitle breadcrumbs={companiesBreadCrumbs}>Sites</PageTitle>
              <SitesList />
            </>
          }
        />
        <Route
          path='meters'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Meters'}></DocumentTitle>
              <PageTitle breadcrumbs={companiesBreadCrumbs}>Meters</PageTitle>
              <MetersList />
            </>
          }
        />
        <Route index element={<Navigate to='/company/list' />} />
      </Route>
    </Routes>
  )
}

export default CompanyPage
